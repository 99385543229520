import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { RootState } from '../redux'

interface AppRouterI {
  path: string
  location?: any
  exact?: boolean
  component: FC
  auth?: string
  role?: string
  status?: string
}

interface routeI {
  pathname: string
  search?: string
}
interface statusRoutesI {
  [key: string]: routeI
  'password-reset': routeI
  active: routeI
}

const roles = ['admin', 'schoolAdmin', 'coach', 'athlete']

const AppRouter: FC<AppRouterI> = ({ path, location, exact, component, auth, role, status }) => {
  const { user } = useSelector((state: RootState) => state.auth)
  const statusRoutes: statusRoutesI = {
    'password-reset': {
      pathname: `/users/${user?._id}`,
      search: '?tab=Security',
    },
    deactivated: { pathname: '/password_reset' },
    active: { pathname: '/' },
  }
  if (auth === 'loggedIn') {
    if (!user) return <Redirect to={{ pathname: '/login', state: { from: location } }} />
    if (status && status !== user.status)
      return <Redirect to={{ ...statusRoutes[user.status], state: { from: location } }} />
    if (role && roles.indexOf(role) < roles.indexOf(user.role))
      return <Redirect to={{ pathname: '/unauthorized', state: { from: location } }} />
  }
  if (auth === 'loggedOut') {
    if (user) return <Redirect to={{ pathname: '/', state: { from: location } }} />
  }

  return <Route exact={exact || false} path={path} component={component} />
}

export default AppRouter
