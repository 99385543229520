import { FC, lazy, Suspense, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { dispatchType } from '../redux'
import { isLoggedIn } from '../redux/auth'
import BrowserTitle from '../components/browserTitle'
import Router from './router'
import IndexRouter from './indexRouter'
import usePathTitle from '../hooks/usePathTitle'

import Loading from '../pages/loading'
import { useWindowWatch } from './useWindowWatch'
const LoginPage = lazy(() => import('../pages/login'))
const SchoolsPage = lazy(() => import('../pages/schools'))
const SchoolPage = lazy(() => import('../pages/school'))
const CoachesPage = lazy(() => import('../pages/coaches'))
const AthletesPage = lazy(() => import('../pages/athletes'))
const UserPage = lazy(() => import('../pages/user'))
const TeamsPage = lazy(() => import('../pages/teams'))
const TeamPage = lazy(() => import('../pages/team'))
const VideosPage = lazy(() => import('../pages/videos'))
const NotFound = lazy(() => import('../pages/notFound'))
const Unauthorized = lazy(() => import('../pages/unauthorized'))
const EmailLinkPage = lazy(() => import('../pages/emailLink'))

interface NavigationI {
  location: any
}

const Navigation: FC<NavigationI> = ({ location }) => {
  const [loading, setLoading] = useState<boolean>(true)
  useWindowWatch()

  const dispatch: dispatchType = useDispatch()
  useEffect(() => {
    setLoading(true)
    dispatch(
      isLoggedIn(() => {
        setLoading(false)
      })
    )
  }, [dispatch])

  const browserTitle = usePathTitle()

  if (loading) return <Loading />

  return (
    <>
      <BrowserTitle title={browserTitle} />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/">
            <IndexRouter />
          </Route>
          <Router auth="loggedOut" exact path="/login" component={LoginPage} location={location} />
          <Router auth="loggedOut" exact path="/forgotten_password" component={LoginPage} location={location} />
          <Router
            auth="loggedIn"
            status="active"
            role="admin"
            exact
            path="/schools"
            component={SchoolsPage}
            location={location}
          />
          <Router
            auth="loggedIn"
            status="active"
            exact
            path="/schools/:id"
            component={SchoolPage}
            location={location}
          />
          <Router auth="loggedIn" status="active" exact path="/coaches" component={CoachesPage} location={location} />
          <Router auth="loggedIn" status="active" exact path="/athletes" component={AthletesPage} location={location} />
          <Router auth="loggedIn" exact path="/users/:id" component={UserPage} location={location} />
          <Router auth="loggedIn" status="active" exact path="/teams" component={TeamsPage} location={location} />
          <Router auth="loggedIn" status="active" exact path="/teams/:id" component={TeamPage} location={location} />
          <Router auth="loggedIn" status="active" exact path="/videos" component={VideosPage} location={location} />
          <Router path="/workout_of_the_week_video/:videoName?" exact component={EmailLinkPage} location={location} />
          <Router path="/unauthorized" exact component={Unauthorized} location={location} />
          <Router path="*" component={NotFound} location={location} />
        </Switch>
      </Suspense>
    </>
  )
}

export default withRouter(Navigation)
