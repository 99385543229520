import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { RootState } from '../redux'

const IndexRouter: FC = () => {
  const { user } = useSelector((state: RootState) => state.auth)
  if (!user) return <Redirect to={{ pathname: '/login' }} />
  if (user.role === 'admin') return <Redirect to={{ pathname: '/schools' }} />
  if (user.role === 'coach') return <Redirect to={{ pathname: `/teams` }} />
  return <Redirect to={{ pathname: `/users/${user._id}` }} />
}

export default IndexRouter
