import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dispatchType } from '..'
import { getSchoolAPI, updateSchoolPropertyAPI } from '../../api'
import { callbackI, schoolI } from './../../types'

interface schoolInitialStateI {
  school: schoolI | null
}
const initialState: schoolInitialStateI = {
  school: null,
}

interface UpdatePropertyI {
  name: string
  value: any
}

const schoolSlice = createSlice({
  name: 'school',
  initialState,
  reducers: {
    setSchool: (state, action: PayloadAction<schoolI | null>) => {
      state.school = action.payload
    },
    setSchoolProperty: (state, action: PayloadAction<UpdatePropertyI>) => {
      let { name, value } = action.payload
      if (state.school) state.school[name] = value
    },
  },
  extraReducers: {
    logout: (state) => {
      state.school = null
    },
  },
})

const { reducer, actions } = schoolSlice
export const { setSchool, setSchoolProperty } = actions
export default reducer

export const getSchool = (id: string, cb: callbackI) => async (dispatch: dispatchType) => {
  try {
    const { data } = await getSchoolAPI(id)
    if (data.status === 'success') dispatch(setSchool(data.value.school))
    cb()
  } catch (error) {
    cb(null, error.response.data.error_message)
  }
}
export const updateSchoolProperty =
  (id: string, name: string, value: any, cb: callbackI) => async (dispatch: dispatchType) => {
    try {
      const { data } = await updateSchoolPropertyAPI(id, name, value)
      if (data.status === 'success') dispatch(setSchoolProperty({ name, value: data.value[name] }))
      cb()
    } catch (error) {
      cb(null, error.response.data.error_message)
    }
  }
