import { createSlice } from '@reduxjs/toolkit'

interface sideNavInitialStateI {
  showSideNav: boolean
}
const initialState: sideNavInitialStateI = {
  showSideNav: false,
}

const sideNavSlice = createSlice({
  name: 'sideNav',
  initialState,
  reducers: {
    toggleSideNav: (state) => {
      state.showSideNav = !state.showSideNav
    },
  },
})

export const { toggleSideNav } = sideNavSlice.actions
const { reducer } = sideNavSlice
export default reducer
