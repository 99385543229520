import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dispatchType } from '..'
import {
  getScoresAPI,
  getScoreTotalsAPI,
  getUserAPI,
  updateUserPropertyAPI,
  scoreUserAPI,
  getMindEnergyAPI,
  setMindScoreAPI,
  setEnergyScoreAPI,
} from '../../api'
import { energyI, mindI } from '../../types/mindEnergy'
import { score, scoreTotals } from '../../types/scores'
import { callbackI, userI } from './../../types'

interface userInitialStateI {
  user: userI | null
  scoreTotals: scoreTotals | null
  scores: score[]
  mind: mindI | null
  energy: energyI | null
}
const initialState: userInitialStateI = {
  user: null,
  scoreTotals: null,
  scores: [],
  mind: null,
  energy: null,
}

interface UpdatePropertyI {
  name: string
  value: any
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ user: userI }>) => {
      state.user = action.payload.user
    },
    setUserScore: (state, action: PayloadAction<number>) => {
      if (state.user) state.user.score = action.payload
    },
    setScores: (state, action: PayloadAction<{ scores: score[] }>) => {
      state.scores = action.payload.scores
    },
    addScoreObject: (state, action: PayloadAction<score>) => {
      state.scores.unshift(action.payload)
    },
    setScoreTotals: (state, action: PayloadAction<{ scoreTotals: scoreTotals }>) => {
      state.scoreTotals = action.payload.scoreTotals
    },
    setMind: (state, action: PayloadAction<{ mind: mindI }>) => {
      state.mind = action.payload.mind
    },
    setEnergy: (state, action: PayloadAction<{ energy: energyI }>) => {
      state.energy = action.payload.energy
    },
    unsetUser: (state) => {
      state.user = initialState.user
      state.scoreTotals = initialState.scoreTotals
      state.scores = initialState.scores
    },
    setUserProperty: (state, action: PayloadAction<UpdatePropertyI>) => {
      let { name, value } = action.payload
      if (state.user) state.user[name] = value
    },
  },
  extraReducers: {
    logout: (state) => {
      state.user = initialState.user
      state.scoreTotals = initialState.scoreTotals
      state.scores = initialState.scores
    },
  },
})

const { reducer, actions } = userSlice
export const {
  setUser,
  setUserScore,
  setScoreTotals,
  setScores,
  setMind,
  setEnergy,
  addScoreObject,
  unsetUser,
  setUserProperty,
} = actions
export default reducer

export const getUser = (id: string, cb: callbackI) => async (dispatch: dispatchType) => {
  try {
    const { data: user } = await getUserAPI(id)
    if (user.status === 'success') dispatch(setUser(user.value))
    const { data: scoreTotals } = await getScoreTotalsAPI(id)
    if (scoreTotals.status === 'success') dispatch(setScoreTotals(scoreTotals.value))
    const { data: scores } = await getScoresAPI(id)
    if (scores.status === 'success') dispatch(setScores(scores.value))
    cb()
  } catch (error) {
    cb(null, error.response.data.error_message)
  }
}

export const updateUserProperty =
  (id: string, name: string, value: any, cb: callbackI) => async (dispatch: dispatchType) => {
    try {
      const { data } = await updateUserPropertyAPI(id, name, value)
      if (data.status === 'success') dispatch(setUserProperty({ name, value: data.value[name] }))
      cb()
    } catch (error) {
      cb(null, error.response.data.error_message)
    }
  }
export const scoreAthlete =
  (userId: string, score: number, exerciseId: string, cb: callbackI) => async (dispatch: dispatchType) => {
    try {
      const { data } = await scoreUserAPI(userId, score, exerciseId)
      const { newScore, newScoreTotals, scoreObject } = data.value
      if (data.status === 'success') {
        dispatch(setUserScore(newScore))
        dispatch(addScoreObject(scoreObject))
        dispatch(setScoreTotals({ scoreTotals: newScoreTotals }))
      }
      cb()
    } catch (error) {
      cb(null, error.response.data.error_message)
    }
  }
export const getMindEnergy = (userId: string, cb: callbackI) => async (dispatch: dispatchType) => {
  try {
    const { data } = await getMindEnergyAPI(userId)
    const { mind, energy } = data.value
    if (data.status === 'success') {
      dispatch(setMind({ mind }))
      dispatch(setEnergy({ energy }))
    }
    cb()
  } catch (error) {
    cb(null, error.response.data.error_message)
  }
}
export const setMindScore = (userId: string, scores: mindI, cb: callbackI) => async (dispatch: dispatchType) => {
  try {
    const { data } = await setMindScoreAPI(userId, scores)
    if (data.status === 'success') {
      dispatch(setMind(data.value))
    }
    cb()
  } catch (error) {
    cb(null, error.response.data.error_message)
  }
}
export const setEnergyScore = (userId: string, scores: energyI, cb: callbackI) => async (dispatch: dispatchType) => {
  try {
    const { data } = await setEnergyScoreAPI(userId, scores)
    if (data.status === 'success') {
      dispatch(setEnergy(data.value))
    }
    cb()
  } catch (error) {
    cb(null, error.response.data.error_message)
  }
}
