import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dispatchType } from '..'
import { createTeamAPI, getTeamsAPI } from '../../api'
import { callbackI, loadAndSearchFunctionI, teamI } from './../../types'

interface teamsInitialStateI {
  teams: teamI[]
  total: number
}
const initialState: teamsInitialStateI = {
  teams: [],
  total: 0,
}

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeams: (state, action: PayloadAction<{ teams: teamI[]; total: number }>) => {
      state.teams = action.payload.teams
      state.total = action.payload.total
    },
    resetTeams: (state) => {
      state.teams = []
      state.total = 0
    },
    addTeam: (state, action: PayloadAction<teamI>) => {
      state.teams.push(action.payload)
    },
  },
  extraReducers: {
    logout: (state) => {
      state.teams = []
      state.total = 0
    },
  },
})

const { reducer, actions } = teamsSlice
export const { setTeams, resetTeams, addTeam } = actions
export default reducer

export const getTeams: loadAndSearchFunctionI =
  (cb: callbackI, search, page, limit, sort, schoolId: string) => async (dispatch: dispatchType) => {
    try {
      const { data } = await getTeamsAPI(schoolId, search, page, limit, sort)
      if (data.status === 'success') dispatch(setTeams(data.value))
      cb()
    } catch (error) {
      cb(null, error.response.data.error_message)
    }
  }
export const createTeam = (name: string, schoolId: string, cb: callbackI) => async (dispatch: dispatchType) => {
  try {
    const { data } = await createTeamAPI(name, schoolId)
    if (data.status === 'success') dispatch(addTeam(data.value.team))
    cb(data.value.team._id, null)
  } catch (error) {
    cb(null, error.response.data.error_message)
  }
}
