import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dispatchType } from '..'
import { getUsersAPI, createUserAPI, scoreUsersAPI, addToTeamAPI, removeFromTeamAPI } from '../../api'
import { teamSmallI } from '../../types/team'
import { callbackI, loadAndSearchFunctionI, teamI, userI } from './../../types'

interface usersTableInitialStateI {
  users: userI[]
  total: number
  selected: Array<string>
  scoring: boolean
  teamSelect: { open: boolean; schoolId: string | null }
}
const initialState: usersTableInitialStateI = {
  users: [],
  total: 0,
  selected: [],
  scoring: false,
  teamSelect: { open: false, schoolId: null },
}

const usersTableSlice = createSlice({
  name: 'usersTable',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<{ users: userI[]; total: number }>) => {
      state.users = action.payload.users
      state.total = action.payload.total
      state.selected = []
    },
    resetUsers: (state) => {
      state.users = []
      state.selected = []
      state.total = 0
    },
    addUser: (state, action: PayloadAction<userI>) => {
      state.users.unshift(action.payload)
      state.total += 1
    },
    toggleSelected: (state, action: PayloadAction<string>) => {
      const id = action.payload
      if (state.selected.includes(id)) state.selected = state.selected.filter((i) => i !== id)
      else state.selected.push(id)
    },
    toggleAll: (state) => {
      state.selected = state.selected.length === state.users.length ? [] : state.users.map((user) => user._id)
    },
    setBulkScoring: (state, action: PayloadAction<boolean>) => {
      state.scoring = action.payload
    },
    removeFromTeam: (state, action: PayloadAction<{ userIds: string[] }>) => {
      const { userIds } = action.payload
      state.users = state.users.filter((user) => !userIds.includes(user._id))
    },
    setTeamSelect: (state, action: PayloadAction<{ open: boolean; schoolId: string | null }>) => {
      state.teamSelect = { ...action.payload }
    },
    updateUsersTeam: (state, action: PayloadAction<{ userIds: string[]; teamObject: teamSmallI }>) => {
      const { userIds, teamObject } = action.payload
      state.users = state.users.map((user) => {
        if (userIds.includes(user._id)) user.teams.push(teamObject)
        return user
      })
    },
    setUserScores: (state, action: PayloadAction<{ userId: string; newScore: number }[]>) => {
      state.users = state.users.map((user) => {
        const newScoreObj = action.payload.find((obj) => obj.userId === user._id)
        if (newScoreObj) user.score = newScoreObj.newScore
        return user
      })
    },
  },
  extraReducers: {
    logout: (state) => {
      state.users = []
      state.total = 0
      state.selected = []
      state.scoring = false
    },
  },
})

const { reducer, actions } = usersTableSlice
export const {
  setUsers,
  resetUsers,
  addUser,
  toggleAll,
  toggleSelected,
  setBulkScoring,
  setTeamSelect,
  updateUsersTeam,
  setUserScores,
  removeFromTeam,
} = actions
export default reducer

export const removeUsersFromTeam = (userIds: string[], teamId: string) => async (dispatch: dispatchType) => {
  try {
    const { data } = await removeFromTeamAPI(userIds, teamId)
    if (data.status === 'success') {
      dispatch(removeFromTeam({ userIds }))
    }
  } catch (error) {
    console.log(error)
  }
}

export const getUsers: loadAndSearchFunctionI =
  (cb: callbackI, search, page, limit, sort, schoolId: string | null, teamId: string | null, role: string | null) =>
  async (dispatch: dispatchType) => {
    try {
      const { data } = await getUsersAPI(schoolId, teamId, role, search, page, limit, sort)
      if (data.status === 'success') dispatch(setUsers(data.value))
      cb()
    } catch (error) {
      cb(null, error.response.data.error_message)
    }
  }
export const createUser =
  (name: string, email: string, schoolId: string | null, teamId: string | null, role: string, cb: callbackI) =>
  async (dispatch: dispatchType) => {
    try {
      const { data } = await createUserAPI(name, email, schoolId, teamId, role)
      if (data.status === 'success') dispatch(addUser(data.value))
      cb()
    } catch (error) {
      cb(null, error.response.data.error_message)
    }
  }
export const scoreAthletes =
  (userIds: string[], score: number, exerciseId: string, cb: callbackI) => async (dispatch: dispatchType) => {
    try {
      const { data } = await scoreUsersAPI(userIds, score, exerciseId)
      const { newScoresArray } = data.value
      if (data.status === 'success') {
        dispatch(setUserScores(newScoresArray))
      }
      cb()
    } catch (error) {
      cb(null, error.response.data.error_message)
    }
  }

export const addToTeam = (userIds: string[], teamId: string, cb: callbackI) => async (dispatch: dispatchType) => {
  try {
    const { data } = await addToTeamAPI(userIds, teamId)
    const { teamObject } = data.value
    if (data.status === 'success') {
      dispatch(updateUsersTeam({ userIds, teamObject }))
    }
    cb()
  } catch (error) {
    cb(null, error.response.data.error_message)
  }
}
