import axios from 'axios'
import { serverUrl } from '../global'
import { CreateGoalType, GoalType } from '../types'
import { energyI, mindI } from '../types/mindEnergy'

const headers = { withCredentials: true }

type strnull = string | null

// auth

export const loginAPI = async (email: string, password: string) => {
  return axios.post(`${serverUrl}/auth/login`, { email, password }, headers)
}
export const isLoggedInAPI = async () => {
  return axios.get(`${serverUrl}/auth/isLoggedIn`, headers)
}
export const logoutAPI = async () => {
  axios.get(`${serverUrl}/auth/logout`, headers)
}
export const forgottenPasswordAPI = async (email: string) => {
  return axios.post(`${serverUrl}/auth/forgottenPassword`, { email }, headers)
}
export const resetPasswordAPI = async (password: string, newPassword: string) => {
  return axios.post(`${serverUrl}/auth/resetPassword`, { password, newPassword }, headers)
}

// schools

export const createSchoolAPI = async (name: string) => {
  const path = `${serverUrl}/schools/`
  const body = { name }
  return axios.post(path, body, headers)
}
export const getSchoolsAPI = async (search: strnull, page: strnull, limit: strnull, sort: strnull) => {
  let path = `${serverUrl}/schools?`
  if (search) path += `&name=${search}`
  if (page) path += `&page=${page}`
  if (limit) path += `&limit=${limit}`
  if (sort) path += `&sort=${sort}`
  return axios.get(path, headers)
}
export const getSchoolAPI = async (id: string) => {
  let path = `${serverUrl}/schools/${id}`
  return axios.get(path, headers)
}
export const updateSchoolPropertyAPI = async (id: string, name: string, value: any) => {
  const path = `${serverUrl}/schools/${id}`
  const body = { name, value }
  return axios.put(path, body, headers)
}

// users

export const getUsersAPI = async (
  schoolId: strnull,
  teamId: strnull,
  role: strnull,
  search: strnull,
  page: strnull,
  limit: strnull,
  sort: strnull
) => {
  let path = `${serverUrl}/users?`
  if (search) path += `&name=${search}`
  if (schoolId) path += `&schoolId=${schoolId}`
  if (teamId) path += `&teamId=${teamId}`
  if (role) path += `&role=${role}`
  if (page) path += `&page=${page}`
  if (limit) path += `&limit=${limit}`
  if (sort) path += `&sort=${sort}`
  return axios.get(path, headers)
}
export const getUserMainInfoAPI = async (id: string) => {
  let path = `${serverUrl}/users/${id}/minInfo`
  return axios.get(path, headers)
}
export const getUserAPI = async (id: string) => {
  let path = `${serverUrl}/users/${id}`
  return axios.get(path, headers)
}
export const updateUserPropertyAPI = async (id: string, name: string, value: any) => {
  const path = `${serverUrl}/users/${id}`
  const body = { name, value }
  return axios.put(path, body, headers)
}
export const createUserAPI = async (
  name: string,
  email: string,
  schoolId: string | null,
  teamId: string | null,
  role: string
) => {
  const path = `${serverUrl}/users`
  const body = { name, email, schoolId, teamId, role }
  return axios.post(path, body, headers)
}

export const addToTeamAPI = async (userIds: string[], teamId: string) => {
  const path = `${serverUrl}/users/add_to_team/${teamId}`
  const body = { userIds }
  return axios.put(path, body, headers)
}

export const removeFromTeamAPI = async (userIds: string[], teamId: string) => {
  const path = `${serverUrl}/users/remove_from_team/${teamId}`
  const body = { userIds }
  return axios.put(path, body, headers)
}

// teams

export const getTeamsAPI = async (schoolId: strnull, search: strnull, page: strnull, limit: strnull, sort: strnull) => {
  let path = `${serverUrl}/teams?`
  if (search) path += `&name=${search}`
  if (schoolId) path += `&schoolId=${schoolId}`
  if (page) path += `&page=${page}`
  if (limit) path += `&limit=${limit}`
  if (sort) path += `&sort=${sort}`

  return axios.get(path, headers)
}
export const createTeamAPI = async (name: string, schoolId: string) => {
  const path = `${serverUrl}/teams/`
  const body = { name, schoolId }
  return axios.post(path, body, headers)
}
export const getTeamAPI = async (id: string) => {
  let path = `${serverUrl}/teams/${id}`
  return axios.get(path, headers)
}
export const deleteTeamAPI = async (id: string) => {
  let path = `${serverUrl}/teams/${id}`
  return axios.delete(path, headers)
}
export const updateTeamPropertyAPI = async (id: string, name: string, value: any) => {
  const path = `${serverUrl}/teams/${id}`
  const body = { name, value }
  return axios.put(path, body, headers)
}

// aws s3

export const getSignedRequestAPI = async (fileName: string, fileType: string) => {
  return axios.get(`${serverUrl}/images/sign-s3?fileName=${fileName}&fileType=${fileType}`)
}

// scoring

export const getScoreTotalsAPI = async (id: string) => {
  const path = `${serverUrl}/scores/totals/${id}`
  return axios.get(path, headers)
}

export const getScoresAPI = async (id: string) => {
  const path = `${serverUrl}/scores/${id}`
  return axios.get(path, headers)
}

export const scoreUserAPI = async (userId: string, score: number, exerciseId: string) => {
  const path = `${serverUrl}/scores/${userId}`
  return axios.put(path, { score, exerciseId }, headers)
}
export const scoreUsersAPI = async (userIds: string[], score: number, exerciseId: string) => {
  const path = `${serverUrl}/scores`
  return axios.put(path, { userIds, score, exerciseId }, headers)
}

// exercises

export const getExercisesAPI = async () => {
  const path = `${serverUrl}/exercises`
  return axios.get(path, headers)
}

// mind energy

export const getMindEnergyAPI = async (id: string) => {
  const path = `${serverUrl}/mindEnergy/${id}`
  return axios.get(path, headers)
}

export const setMindScoreAPI = async (id: string, scores: mindI) => {
  const path = `${serverUrl}/mindEnergy/mind/${id}`
  return axios.post(path, { scores }, headers)
}
export const setEnergyScoreAPI = async (id: string, scores: energyI) => {
  const path = `${serverUrl}/mindEnergy/energy/${id}`
  return axios.post(path, { scores }, headers)
}

// videos

export const getVideoCategoriesAPI = async () => {
  const path = `${serverUrl}/videos/categories`
  return axios.get(path, headers)
}
export const getPromotedVideoAPI = async () => {
  const path = `${serverUrl}/videos/promoted`
  return axios.get(path, headers)
}

// goals

export const getGoalsAPI = async (athleteId: string) => {
  const path = `${serverUrl}/goals/${athleteId}`
  return axios.get(path, headers)
}

export const createGoalAPI = async (goal: CreateGoalType) => {
  const path = `${serverUrl}/goals/create`
  return axios.post(path, goal, headers)
}

export const updateGoalAPI = async (goalId: string, value: number) => {
  const path = `${serverUrl}/goals/${goalId}`
  return axios.put(path, { value }, headers)
}
