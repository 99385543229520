import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dispatchType } from '..'
import { createGoalAPI, getGoalsAPI, updateGoalAPI } from '../../api'
import { callbackI, GoalType, CreateGoalType } from './../../types'

interface goalsInitialStateI {
  goals: GoalType[]
}
const initialState: goalsInitialStateI = {
  goals: [],
}

const goalsSlice = createSlice({
  name: 'goals',
  initialState,
  reducers: {
    setGoals: (state, action: PayloadAction<GoalType[]>) => {
      state.goals = action.payload
    },
    addGoal: (state, action: PayloadAction<GoalType>) => {
      state.goals.push(action.payload)
    },
    updateGoal: (state, action: PayloadAction<{ goalId: string; value: number }>) => {
      const { goalId, value } = action.payload
      state.goals = state.goals.map((goal) => {
        if (goal._id === goalId) {
          goal.currents.push({ value, createdAt: Date.now() })
        }
        return goal
      })
    },
  },
  extraReducers: {
    logout: (state) => {
      state.goals = []
    },
  },
})

const { reducer, actions } = goalsSlice
export const { setGoals, addGoal, updateGoal } = actions
export default reducer

export const getGoals = (athleteId: string, cb: callbackI) => async (dispatch: dispatchType) => {
  try {
    const { data } = await getGoalsAPI(athleteId)
    if (data.status === 'success') dispatch(setGoals(data.value))
    cb()
  } catch (error) {
    cb(null, error.response.data.error_message)
  }
}

export const createGoal = (goal: CreateGoalType, cb: callbackI) => async (dispatch: dispatchType) => {
  try {
    const { data } = await createGoalAPI(goal)
    if (data.status === 'success') dispatch(addGoal(data.value))
    cb()
  } catch (error) {
    cb(null, error.response.data.error_message)
  }
}
export const updateGoalFn = (goalId: string, value: number, cb: callbackI) => async (dispatch: dispatchType) => {
  try {
    const { data } = await updateGoalAPI(goalId, value)
    if (data.status === 'success') dispatch(updateGoal({ goalId, value }))
    cb()
  } catch (error) {
    cb(null, error.response.data.error_message)
  }
}
