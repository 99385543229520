import { configureStore } from '@reduxjs/toolkit'
//import logger from 'redux-logger'

import windowReducer from './window'
import auth from './auth'
import sideNav from './sideNav'
import schools from './schools'
import school from './school'
import teams from './teams'
import team from './team'
import users from './users'
import user from './user'
import videos from './videos'
import goals from './goals'

const store = configureStore({
  reducer: {
    windowR: windowReducer,
    auth,
    sideNav,
    schools,
    school,
    teams,
    team,
    users,
    user,
    videos,
    goals,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(), //.concat(logger),
  devTools: true,
})
export default store
export type dispatchType = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
