import { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Film, Icon, LogOut, Video } from 'react-feather'
import clsx from 'clsx'
import { RootState } from '../../redux'
import { logout } from '../../redux/auth'
import './style.scss'
import { toggleSideNav } from '../../redux/sideNav'

interface SideNavLinkI {
  loc: string
  path: string
  query?: string
  text: string
  IconC?: Icon
  textIcon?: string
}
const SideNavLink: FC<SideNavLinkI> = ({ loc, path, query = '', text, IconC, textIcon }) => {
  const isActive = useMemo(() => {
    return loc === path
  }, [loc, path])

  return (
    <Link to={path + query} className={clsx('nav-link', isActive && 'nav-link--active')}>
      <div className="nav-link__icon-wrapper">
        {IconC && <IconC className="nav-link__icon" />}
        {textIcon && <div className="nav-link__icon">{textIcon}</div>}
      </div>
      <div className="nav-link__text">{text}</div>
    </Link>
  )
}

const NavLogout: FC = () => {
  const dispatch = useDispatch()
  const logoutFunc = () => {
    dispatch(logout())
  }
  return (
    <button onClick={logoutFunc} className="nav-link nav-link--logout">
      <div className="nav-link__icon-wrapper">
        <LogOut className="nav-link__icon" />
      </div>
      <div className="nav-link__text">LogOut</div>
    </button>
  )
}

const SideNav: FC = () => {
  const loc = '/' + useLocation().pathname.split('/')[1]
  const { showSideNav } = useSelector((state: RootState) => state.sideNav)
  const { user } = useSelector((state: RootState) => state.auth)

  const dispatch = useDispatch()
  const toggleNav = () => {
    dispatch(toggleSideNav())
  }

  if (!user) return null
  return (
    <div className={clsx('side-nav', showSideNav && 'side-nav--open')}>
      <div onClick={toggleNav} className="side-nav__inner-wrapper">
        <div className="side-nav__inner">
          {user.role === 'admin' ? (
            <SideNavLink loc={loc} path="/schools" text="Schools" textIcon={'S'} />
          ) : user.role !== 'athlete' ? (
            <SideNavLink loc={loc} path={`/schools/${user.school}`} text="My School" textIcon={'S'} />
          ) : null}
          {user.role !== 'athlete' && (
            <>
              <SideNavLink loc={loc} path="/teams" text="Teams" textIcon={'T'} />
              <SideNavLink loc={loc} path="/coaches" text="Coaches" textIcon={'C'} />
              <SideNavLink loc={loc} path="/athletes" text="Athletes" textIcon={'A'} />
            </>
          )}
          <SideNavLink loc={loc} path="/videos" text="Videos" IconC={Film} />
          <NavLogout />
        </div>
      </div>
    </div>
  )
}

export default SideNav
