import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dispatchType } from '..'
import { getPromotedVideoAPI, getVideoCategoriesAPI } from '../../api'
import { callbackI, videoCategoryI, videoI } from './../../types'

interface videosInitialStateI {
  videoCategories: videoCategoryI[]
  promotedVideo: videoI | null
}
const initialState: videosInitialStateI = {
  videoCategories: [],
  promotedVideo: null,
}

const videosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    setvideoCategories: (state, action: PayloadAction<videoCategoryI[]>) => {
      state.videoCategories = action.payload
    },
    resetVideos: (state) => {
      state.videoCategories = []
      state.promotedVideo = null
    },
    setPromotedVideo: (state, action: PayloadAction<videoI>) => {
      state.promotedVideo = action.payload
    },
  },
  extraReducers: {
    logout: (state) => {
      state.videoCategories = []
      state.promotedVideo = null
    },
  },
})

const { reducer, actions } = videosSlice
export const { setvideoCategories, setPromotedVideo, resetVideos } = actions
export default reducer

export const getVideoCategories = (cb: callbackI) => async (dispatch: dispatchType) => {
  try {
    const { data } = await getVideoCategoriesAPI()
    if (data.status === 'success') {
      dispatch(setvideoCategories(data.value.videoCategories))
      if (cb) cb()
    }
  } catch (error) {
    console.log(error)
  }
}
export const getPromotedVideo = (cb: callbackI) => async (dispatch: dispatchType) => {
  try {
    const { data } = await getPromotedVideoAPI()
    if (data.status === 'success') {
      dispatch(setPromotedVideo(data.value.video))
      if (cb) cb()
    }
  } catch (error) {
    console.log(error)
  }
}
