import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { RootState } from '../../redux'
import logo from '../../assets/graphics/logo-thin.svg'
import usePathTitle from '../../hooks/usePathTitle'
import { imageRequest } from '../../global'
import './style.scss'
import { Link } from 'react-router-dom'
import { Menu } from 'react-feather'
import { toggleSideNav } from '../../redux/sideNav'

const TopNav: FC = () => {
  const { user } = useSelector((state: RootState) => state.auth)
  let pathTitle = usePathTitle()

  const dispatch = useDispatch()
  const toggleNav = () => {
    dispatch(toggleSideNav())
  }

  if (!user) return null

  const imgR = imageRequest(user.image, 40, 40)
  return (
    <div className={clsx('top-nav')}>
      <div className="top-nav__left">
        <Link to={'/'}>
          <img src={logo} className="logo" alt="logo" />
        </Link>
        <div className="page-title trunc-text">{pathTitle}</div>
      </div>
      <div className="top-nav__icons">
        <Link to={`/users/${user._id}`}>
          <img src={imgR} alt={user.name} className="top-nav__icon-wrapper round" />
        </Link>
        <button onClick={toggleNav} className="nav-toggle">
          <Menu className="nav-toggle__icon" />
        </button>
      </div>
    </div>
  )
}

export default TopNav
