import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dispatchType } from '..'
import { deleteTeamAPI, getTeamAPI, updateTeamPropertyAPI } from '../../api'
import { callbackI, teamI } from './../../types'

interface teamInitialStateI {
  team: teamI | null
}
const initialState: teamInitialStateI = {
  team: null,
}

interface UpdatePropertyI {
  name: string
  value: any
}

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    setTeam: (state, action: PayloadAction<teamI | null>) => {
      state.team = action.payload
    },
    setTeamProperty: (state, action: PayloadAction<UpdatePropertyI>) => {
      let { name, value } = action.payload
      if (state.team) state.team[name] = value
    },
  },
  extraReducers: {
    logout: (state) => {
      state.team = null
    },
  },
})

const { reducer, actions } = teamSlice
export const { setTeam, setTeamProperty } = actions
export default reducer

export const getTeam = (id: string, cb: callbackI) => async (dispatch: dispatchType) => {
  try {
    const { data } = await getTeamAPI(id)
    if (data.status === 'success') dispatch(setTeam(data.value.team))
    cb()
  } catch (error) {
    cb(null, error.response.data.error_message)
  }
}

export const updateTeamProperty =
  (id: string, name: string, value: any, cb: callbackI) => async (dispatch: dispatchType) => {
    try {
      const { data } = await updateTeamPropertyAPI(id, name, value)
      if (data.status === 'success') dispatch(setTeamProperty({ name, value: data.value[name] }))
      cb()
    } catch (error) {
      cb(null, error.response.data.error_message)
    }
  }

export const deleteTeam = (id: string, cb: callbackI) => async (dispatch: dispatchType) => {
  try {
    await deleteTeamAPI(id)
    cb()
  } catch (error) {
    cb(null, error.response.data.error_message)
  }
}
