import React from 'react'
import Logo from './logo'
import './style.scss'

const LoadingPage = () => {
  return (
    <div className="page loading-page">
      <Logo />
    </div>
  )
}
export default LoadingPage
