export const roles = []

// export const serverUrl = `http://localhost:3000`
export const serverUrl = `https://api.thrivenation.co.nz`
export const videoBaseUrl = 'https://thrivenation.s3.amazonaws.com/'
export const imageBaseUrl = 'https://d3bfxs37wxuorx.cloudfront.net'

export const medalColors = ['#444', '#cd7f32', 'silver', '#ffbe01']
export const medalNames = ['attempt', 'bronze', 'silver', 'gold']

export const imageRequest = (image: string, width: number, height?: number) =>
  image
    ? `${imageBaseUrl}/${btoa(
        JSON.stringify({
          bucket: 'thrivenation',
          key: image,
          edits: {
            resize: {
              width,
              height,
              fit: 'inside',
            },
          },
        })
      )}`
    : ''
