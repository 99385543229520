import { createSlice } from '@reduxjs/toolkit'
import { windowI } from './../../types'

const initialState: windowI = {
  width: window.innerWidth,
  height: window.innerHeight,
  isMobile: false,
  isIpad: false,
  scroll: 0,
}

const windowSlice = createSlice({
  name: 'window',
  initialState,
  reducers: {
    resized: (state, { payload }) => {
      state.width = payload.innerWidth
      state.height = payload.innerHeight
      state.isIpad = payload.innerWidth <= 1024 && payload.innerWidth >= 600
      state.isMobile = payload.innerWidth <= 600
    },
    scrolled: (state, { payload }) => {
      state.scroll = payload
    },
  },
})

const { actions, reducer } = windowSlice
export const { resized, scrolled } = actions
export default reducer
