import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { scrolled, resized } from '../redux/window'

export const useWindowWatch = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const setWindow = () => {
      dispatch(resized({ innerWidth: window.innerWidth, innerHeight: window.innerHeight }))
    }
    setWindow()
    window.addEventListener('resize', setWindow)
    return () => {
      window.removeEventListener('resize', setWindow)
    }
  }, [])

  useEffect(() => {
    const setWindow = () => {
      dispatch(scrolled(window.scrollY))
    }
    setWindow()
    window.addEventListener('scroll', setWindow)
    return () => {
      window.removeEventListener('scroll', setWindow)
    }
  }, [])
}
