import { FC } from 'react'
import logo from '../../../assets/graphics/logo-thin.svg'
import './style.scss'

const Logo: FC = () => {
  return (
    <div className="logo-wrapper">
      <img className="logo" src={logo} alt="logo" />
      <div className="logo-title">Thrive Nation</div>
    </div>
  )
}

export default Logo
