import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dispatchType } from '..'
import { loginAPI, isLoggedInAPI, logoutAPI, forgottenPasswordAPI, resetPasswordAPI } from '../../api'
import { statuses } from '../../types/user'
import { callbackI, userI } from './../../types'

interface authInitialStateI {
  user: userI | null
  loading: boolean
}
const initialState: authInitialStateI = {
  user: null,
  loading: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<userI | null>) => {
      state.user = action.payload
    },
    updateUserStatus: (state, action: PayloadAction<statuses>) => {
      if (state.user) state.user.status = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

const { reducer, actions } = authSlice
export const { setUser, updateUserStatus, setLoading } = actions
export default reducer

export const isLoggedIn = (cb: callbackI) => async (dispatch: dispatchType) => {
  const { data } = await isLoggedInAPI()
  if (data.status === 'success') dispatch(setUser(data.value.user))
  else dispatch(setUser(null))
  cb()
}

export const login = (email: string, password: string, cb: callbackI) => async (dispatch: dispatchType) => {
  try {
    const { data } = await loginAPI(email, password)
    dispatch(setUser(data.value.user))
    cb()
  } catch (error) {
    dispatch(setUser(null))
    cb(null, error.response.data.error_message)
  }
}

export const forgottenPassword = (email: string, cb: callbackI) => async () => {
  try {
    const { data } = await forgottenPasswordAPI(email)
    if (data.status === 'success') cb()
  } catch (error) {
    cb(null, error.response.data.error_message)
  }
}

export const resetPassword =
  (password: string, newPassword: string, cb: callbackI) => async (dispatch: dispatchType) => {
    try {
      const { data } = await resetPasswordAPI(password, newPassword)
      if (data.status === 'success') {
        cb()
        dispatch(updateUserStatus('active'))
      }
    } catch (error) {
      cb(null, error.response.data.error_message)
    }
  }

export const logout = () => async (dispatch: dispatchType) => {
  await logoutAPI()
  dispatch(setUser(null))
}
