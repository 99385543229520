import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dispatchType } from '..'
import { createSchoolAPI, getSchoolsAPI } from '../../api'
import { schoolI, callbackI, loadAndSearchFunctionI } from './../../types'

interface schoolsInitialStateI {
  schools: Array<schoolI>
  total: number
}

const initialState: schoolsInitialStateI = {
  schools: [],
  total: 0,
}

const schoolsSlice = createSlice({
  name: 'schools',
  initialState,
  reducers: {
    setSchools: (state, action: PayloadAction<{ schools: Array<schoolI>; total: number }>) => {
      state.schools = action.payload.schools
      state.total = action.payload.total
    },
    resetSchools: (state) => {
      state.schools = []
      state.total = 0
    },
    addSchool: (state, action: PayloadAction<schoolI>) => {
      state.schools.push(action.payload)
      state.total++
    },
  },
  extraReducers: {
    logout: (state) => {
      state.schools = []
      state.total = 0
    },
  },
})

const { reducer, actions } = schoolsSlice
export const { setSchools, resetSchools, addSchool } = actions
export default reducer

export const getSchools: loadAndSearchFunctionI = (cb, search, page, limit, sort) => async (dispatch: dispatchType) => {
  try {
    const { data } = await getSchoolsAPI(search, page, limit, sort)
    if (data.status === 'success') dispatch(setSchools(data.value))
    cb()
  } catch (error) {
    cb(null, error.response.data.error_message)
  }
}
export const createSchool = (name: string, cb: callbackI) => async (dispatch: dispatchType) => {
  try {
    const { data } = await createSchoolAPI(name)
    if (data.status === 'success') dispatch(addSchool(data.value.school))
    cb(data.value.school._id, null)
  } catch (error) {
    cb(null, error.response.data.error_message)
  }
}
