import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from '../redux'

const usePathTitle = () => {
  const { school } = useSelector((state: RootState) => state.school)
  const { team } = useSelector((state: RootState) => state.team)
  const { user } = useSelector((state: RootState) => state.user)

  const loc = useLocation().pathname
  let pathTitle = useMemo(() => {
    let splitLoc = loc.replace('/', '').split('/')
    if (!splitLoc.length || !splitLoc[0]) return 'Home'
    if (splitLoc[0] === 'schools' && splitLoc.length > 1) return `${school ? school.name : ''}`
    if (splitLoc[0] === 'teams' && splitLoc.length > 1) return `Team  -  ${team ? team.name : ''}`
    if (splitLoc[0] === 'users' && splitLoc.length > 1) return `${user ? user.name : ''}`

    return splitLoc
      .map((t) => {
        t.replace('_', ' ')
        return t[0].toUpperCase() + t.substr(1)
      })
      .join(' ')
  }, [loc, school, team, user])

  return pathTitle
}

export default usePathTitle
