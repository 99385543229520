import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import Navigation from './navigation'
import store from './redux'
import SideNav from './containers/sideNav'
import TopNav from './containers/topNav'

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <TopNav />
        <div className="root-inner">
          <SideNav />
          <Navigation />
        </div>
      </BrowserRouter>
    </Provider>
  )
}

export default App
